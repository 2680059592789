"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomBool = exports.delay = exports.isNilOrEmpty = void 0;
const isNil = (value) => value === null || value === undefined;
const isEmpty = (value) => (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0);
const isNilOrEmpty = (value) => isNil(value) || isEmpty(value);
exports.isNilOrEmpty = isNilOrEmpty;
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
exports.delay = delay;
const randomBool = (threshold = 0.5) => Math.random() < threshold;
exports.randomBool = randomBool;
