"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MockBetslipService = void 0;
const common_1 = require("@pty-services/common");
const business_1 = require("../business");
const betslip_errors_1 = require("../betslip-errors");
const utils_1 = require("../utils");
// HELPERS
const persistInLocalStorage = (localStorageKey, observable) => {
    const cachedValue = localStorage.getItem(localStorageKey);
    if (cachedValue) {
        try {
            const parsedValue = JSON.parse(cachedValue);
            observable.set(parsedValue);
        }
        catch (err) {
            console.error(`Error parsing "${localStorageKey}": ${err.message}`);
            localStorage.removeItem(localStorageKey);
        }
    }
    const persist = (value) => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    };
    return {
        ...observable,
        update: (updater) => {
            observable.update(value => {
                const updatedValue = updater(value);
                persist(updatedValue);
                return updatedValue;
            });
        },
        set: (value) => {
            observable.set(value);
            persist(value);
        },
    };
};
const generateMockPicks = (picks) => Object.values(picks).map(({ pickId, riskValue, isPlacingBet, isValidatingBet, odds, previousOdds, timeOddsChanged, ...rest }) => ({
    ...rest,
    pickId,
    points: '-1.5',
    odds: odds !== null && odds !== void 0 ? odds : 3.7,
    previousOdds: previousOdds !== null && previousOdds !== void 0 ? previousOdds : 1.56,
    timeOddsChanged: timeOddsChanged !== null && timeOddsChanged !== void 0 ? timeOddsChanged : 0,
    marketTitle: '',
    maxBetAmount: 2000,
    riskValue: riskValue || '',
    hasCashout: false,
    isLive: true,
    isOnHold: false,
    isMarketUnavailable: false,
    isValidatingBet: !!isValidatingBet,
    isPlacingBet: !!isPlacingBet,
    isFetchingPickData: false,
}));
// FACTORY
const PLACE_BET_RESULT_INITIAL_STATE = {
    isFullSuccess: false,
    isPartialSuccess: false,
    succeededPicks: [],
    failedPicks: [],
};
class MockBetslipService {
    constructor() {
        this.currentPicks = common_1.Observable.map(generateMockPicks, persistInLocalStorage('betslip-picks', common_1.Observable.fromValue({})));
        this.placeBetResult = common_1.Observable.fromValue(PLACE_BET_RESULT_INITIAL_STATE);
        this.oddsFormat = common_1.Observable.fromValue(business_1.ODDS_FORMAT.AMERICAN);
        this.quickBetStatus = common_1.Observable.fromValue({
            isActivated: false,
            amount: '',
        });
        this.lineChangeOption = common_1.Observable.fromValue(1);
        this.betslipTab = common_1.Observable.fromValue({
            id: business_1.BETSLIP_SPACE_TABS.BETSLIP,
        });
        this.isToasterOpen = common_1.Observable.fromValue(false);
        this.addPick = ({ pickId, ...rest }) => {
            this.betslipTab.set({ id: business_1.BETSLIP_SPACE_TABS.BETSLIP });
            this.currentPicks.update(picks => {
                picks[pickId] = {
                    ...rest,
                    pickId,
                    label: `Pick ${pickId}`,
                    marketTitle: 'Run Line - Game',
                    riskValue: '',
                    addedAt: Date.now(),
                    initialOdds: rest.odds,
                    marketBoardType: business_1.MARKET_BOARD_TYPES.US,
                    participants: ['Arizona Diamondbacks', 'Los Angeles Dodgers'],
                };
                return picks;
            });
            const quickBetStatus = common_1.Observable.getObservableValue(this.quickBetStatus);
            const isMobile = true;
            if (isMobile && quickBetStatus.isActivated) {
                this.openQuickBetToaster();
            }
        };
        this.validateBet = async (bet) => {
            this.currentPicks.update(picks => {
                for (let pick of Object.values(picks)) {
                    picks[pick.pickId].isValidatingBet = true;
                }
                return picks;
            });
            return new Promise(resolve => {
                setTimeout(() => {
                    this.currentPicks.update(picks => {
                        for (let pick of Object.values(picks)) {
                            picks[pick.pickId].isValidatingBet = false;
                        }
                        return picks;
                    });
                    const hasError = utils_1.randomBool(0.2);
                    resolve({ maxBetAmount: 2000, errorCode: hasError ? 1 : 0 });
                }, 2000);
            });
        };
        this.updatePickRisk = (pickId, riskValue) => {
            this.currentPicks.update(picks => {
                picks[pickId].riskValue = riskValue;
                return picks;
            });
        };
        this.updateQuickBetStatus = (isActivated, amount) => this.quickBetStatus.set({ isActivated, amount });
        this.updateLineChangeOption = (option) => this.lineChangeOption.set(option);
        this.removePick = ({ pickId }) => {
            this.currentPicks.update(picks => {
                delete picks[pickId];
                return picks;
            });
        };
        this.placeBets = async (bets) => {
            this.currentPicks.update(picks => {
                for (let bet of bets) {
                    picks[bet.pickId].isPlacingBet = true;
                }
                return picks;
            });
            return new Promise(resolve => {
                setTimeout(() => {
                    const apiMockResult = bets.map(bet => {
                        const hasError = utils_1.randomBool(0.2);
                        return {
                            pickId: bet.pickId,
                            points: bet.points,
                            odds: bet.odds,
                            errorCode: hasError ? 1 : 0,
                        };
                    });
                    const succeededPicks = [];
                    const failedPicks = [];
                    this.currentPicks.update(picks => {
                        for (let bet of apiMockResult) {
                            const { sport, eventId, marketId, label, marketTitle, riskValue, participants, marketBoardType, } = picks[bet.pickId];
                            if (bet.errorCode === 0) {
                                succeededPicks.push({
                                    sport,
                                    eventId,
                                    marketId,
                                    label,
                                    marketTitle,
                                    riskValue,
                                    participants,
                                    marketBoardType,
                                    ...bet,
                                });
                                delete picks[bet.pickId];
                            }
                            else {
                                failedPicks.push({
                                    sport,
                                    eventId,
                                    marketId,
                                    label,
                                    marketTitle,
                                    ...bet,
                                });
                                picks[bet.pickId].isPlacingBet = false;
                                picks[bet.pickId].errorMessage = betslip_errors_1.mapErrorCodeToBetslipError(bet.errorCode);
                            }
                        }
                        return picks;
                    });
                    const formattedResponse = {
                        isFullSuccess: succeededPicks.length !== 0 && failedPicks.length === 0,
                        isPartialSuccess: succeededPicks.length !== 0 && failedPicks.length !== 0,
                        succeededPicks,
                        failedPicks,
                    };
                    this.placeBetResult.set(formattedResponse);
                    resolve(formattedResponse);
                }, 2000);
            });
        };
        this.clearSelection = () => this.currentPicks.set({});
        this.cleanPlaceBetResult = () => this.placeBetResult.set(PLACE_BET_RESULT_INITIAL_STATE);
        this.openQuickBetToaster = () => {
            this.isToasterOpen.set(true);
        };
        this.closeQuickBetToaster = () => {
            this.isToasterOpen.set(false);
        };
    }
}
exports.MockBetslipService = MockBetslipService;
exports.default = MockBetslipService;
