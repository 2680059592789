"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const business_1 = require("../../business");
const rowExample = {
    title: 'Philadelphia Eagles',
    betType: 'O -29.5',
    pick: '-110',
    event: 'Game',
    amount: 10000,
    status: business_1.MY_BETS_ROW_STATUSES.WON,
    ticketNumber: '456026710-1',
    acceptedDate: new Date(2020, 9, 9, 23, 45),
    type: 'Spread/Run Line',
    toWin: 4.59,
    description: 'Football - NFL - Green Bay Packers  vs Philadelphia Eagles - Spread | 101127 Green Bay Packers pk, +½ -109 For Game | 06/19/2020 | 12:00:00 PM (EST) | Pending',
    hasCashout: false,
    isLive: true,
};
const rowExample1 = {
    ...rowExample,
    status: business_1.MY_BETS_ROW_STATUSES.CASHED_OUT,
};
const rowExample2 = {
    ...rowExample,
    title: 'Buffalo Bills',
    amount: 23.5,
    status: business_1.MY_BETS_ROW_STATUSES.WON,
    isLive: false,
};
const rowExample3 = {
    ...rowExample,
    title: 'New York Giants',
    status: business_1.MY_BETS_ROW_STATUSES.LOST,
    isLive: false,
};
const rowExample4 = {
    ...rowExample,
    title: 'Cleveland Browns',
    status: business_1.MY_BETS_ROW_STATUSES.CANCELLED,
};
const rowExample5 = {
    ...rowExample,
    title: 'Houston Texans',
    amount: 5000.35,
    status: business_1.MY_BETS_ROW_STATUSES.LOST,
};
const rowExample6 = {
    ...rowExample,
    title: 'Denver Broncos',
    amount: 3.5,
    status: business_1.MY_BETS_ROW_STATUSES.LOST,
};
const rowExample7 = {
    ...rowExample,
    title: 'Green Bay Packers',
    betType: 'O +½',
    amount: 5,
    pick: '-109',
    status: business_1.MY_BETS_ROW_STATUSES.WON,
};
const rows = [
    rowExample1,
    rowExample2,
    rowExample3,
    rowExample4,
    rowExample5,
    rowExample6,
    rowExample7,
    rowExample1,
    rowExample2,
    rowExample3,
    rowExample4,
    rowExample5,
    rowExample6,
    rowExample7,
    rowExample1,
    rowExample2,
    rowExample3,
    rowExample4,
    rowExample5,
    rowExample6,
    rowExample7,
];
exports.default = rows;
