"use strict";
// TYPES
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapErrorCodeToBetslipError = exports.betslipErrorCreators = exports.BETSLIP_ERROR_KEYS = void 0;
var BETSLIP_ERROR_KEYS;
(function (BETSLIP_ERROR_KEYS) {
    BETSLIP_ERROR_KEYS["GENERIC"] = "betslip-error:generic";
    BETSLIP_ERROR_KEYS["INVALID_AMOUNT"] = "betslip-error:invalid-amount";
})(BETSLIP_ERROR_KEYS = exports.BETSLIP_ERROR_KEYS || (exports.BETSLIP_ERROR_KEYS = {}));
exports.betslipErrorCreators = {
    genericError: () => ({
        key: BETSLIP_ERROR_KEYS.GENERIC,
    }),
    invalidAmount: () => ({
        key: BETSLIP_ERROR_KEYS.INVALID_AMOUNT,
    }),
};
// TODO: Map error codes to more specific errors
const mapErrorCodeToBetslipError = (errCode) => {
    return exports.betslipErrorCreators.genericError();
};
exports.mapErrorCodeToBetslipError = mapErrorCodeToBetslipError;
