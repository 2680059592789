const createCashoutService = () => ({
  getCashOutWagerList() {
    return new Promise((resolve, reject) => {
      const bets = [
        {
          CustomerId: 'B1564872',
          TicketNumber: 1,
          WagerNumber: 1,
          ItemNumber: 1,
          SelectedParticipantName: 'Kansas City Chiefs',
          Odds: -115,
          EventName: 'San Francisco 49ers @ Kansas City Chiefs',
          MarketName: 'Money Line - Game',
          AmountWagered: 100.0,
          ToWinAmount: 86.96,
          CashoutAmount: 76.85,
          CashoutStatus: 'active',
          isLive: false,
        },
        {
          CustomerId: 'B1564872',
          TicketNumber: 3210938901230,
          WagerNumber: 1,
          ItemNumber: 1,
          SelectedParticipantName: 'NY Giants',
          Odds: 95,
          EventName: 'NY Jets @ NY Giants',
          MarketName: 'Money Line - Game',
          AmountWagered: 100.0,
          ToWinAmount: 86.96,
          CashoutAmount: 11.11,
          CashoutStatus: 'active',
          isLive: true,
        },
      ]
      if (Array.isArray(bets)) {
        setTimeout(() => {
          resolve({
            IsValidModel: true,
            CashoutWagerList: bets,
          })
        }, 1000)
      } else {
        reject(new Error({ ModelErrorMessage: 'Something wrong happened' }))
      }
    })
  },
  requestWagerCashOut(incomingBet, acceptBetChanges) {
    return new Promise((resolve, reject) => {
      const betFromAPI = {
        IsValidModel: true,
        CustomerId: 'B1564872',
        TicketNumber: incomingBet.TicketNumber,
        WagerNumber: 1,
        ItemNumber: 1,
        SelectedParticipantName: 'Updated from API',
        Odds: -115,
        EventName: 'Updated event Name',
        MarketName: 'Money Line - Game',
        AmountWagered: 100.0,
        ToWinAmount: Math.random() * 100,
        CashoutAmount: Math.random() * 100,
        CashoutStatus: 'cashedout',
        IsLive: false,
      }
      if (typeof betFromAPI === 'object') {
        setTimeout(() => {
          resolve(betFromAPI)
        }, 3000)
      } else {
        reject(
          new Error({
            ModelErrorMessage: 'Something wrong happened',
            acceptBetChanges,
          }),
        )
      }
    })
  },
})

export default createCashoutService
