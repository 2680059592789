"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLACE_BET_RESULT_CLOSE_TIMEOUT_MS = exports.TOASTER_MOBILE_BREAKPOINT = exports.BETSLIP_SPACE_TABS = exports.MY_BETS_ROW_STATUSES = exports.MARKET_BOARD_TYPES = exports.ODDS_FORMAT = void 0;
var ODDS_FORMAT;
(function (ODDS_FORMAT) {
    ODDS_FORMAT["AMERICAN"] = "AMERICAN";
    ODDS_FORMAT["DECIMAL"] = "DECIMAL";
    ODDS_FORMAT["FRACTIONAL"] = "FRACTIONAL";
})(ODDS_FORMAT = exports.ODDS_FORMAT || (exports.ODDS_FORMAT = {}));
var MARKET_BOARD_TYPES;
(function (MARKET_BOARD_TYPES) {
    MARKET_BOARD_TYPES["US"] = "us";
    MARKET_BOARD_TYPES["MULTIPLE"] = "multiple";
    MARKET_BOARD_TYPES["REGULAR"] = "regular";
})(MARKET_BOARD_TYPES = exports.MARKET_BOARD_TYPES || (exports.MARKET_BOARD_TYPES = {}));
var MY_BETS_ROW_STATUSES;
(function (MY_BETS_ROW_STATUSES) {
    MY_BETS_ROW_STATUSES["PENDING"] = "pending";
    MY_BETS_ROW_STATUSES["CASHED_OUT"] = "cashed-out";
    MY_BETS_ROW_STATUSES["WON"] = "won";
    MY_BETS_ROW_STATUSES["LOST"] = "lost";
    MY_BETS_ROW_STATUSES["CANCELLED"] = "cancelled";
})(MY_BETS_ROW_STATUSES = exports.MY_BETS_ROW_STATUSES || (exports.MY_BETS_ROW_STATUSES = {}));
var BETSLIP_SPACE_TABS;
(function (BETSLIP_SPACE_TABS) {
    BETSLIP_SPACE_TABS["BETSLIP"] = "betslip";
    BETSLIP_SPACE_TABS["MY_BETS"] = "my-bets";
})(BETSLIP_SPACE_TABS = exports.BETSLIP_SPACE_TABS || (exports.BETSLIP_SPACE_TABS = {}));
exports.TOASTER_MOBILE_BREAKPOINT = 500;
exports.PLACE_BET_RESULT_CLOSE_TIMEOUT_MS = 1000 * 5;
