import 'cashout-lib/dist/cashout-lib.1.4.0'
import '@pty-betslip/widget'
import '@pty-betslip/quickbet-toaster'

// Service mocks
import {
  MockBetslipService,
  MockBetHistoryService,
} from '@pty-betslip/service/dist/mocks'
import { createAuthService, createCashoutService } from './mocks'

const betslipService = new MockBetslipService()
const authService = createAuthService()

document.addEventListener('betslip-ready', event => {
  const betslip = event.detail.component

  betslip.init(
    { sitesUrl: 'https://beta.betonline.ag' },
    {
      authService,
      betslipService,
      betHistoryService: new MockBetHistoryService(),
      cashoutService: createCashoutService(),
    },
  )
})

document.addEventListener('quickbet-toaster-ready', event => {
  const toaster = event.detail.component

  toaster.init({
    betslipService,
    authService,
  })

  betslipService.isToasterOpen.subscribe(value => {
    toaster.isopen = value.toString()
  })
})

// Example pick
const pickButton = document.getElementById('example-pick')

const getRandomOdds = () => (101 + Math.floor(Math.random() * 99)) / 100

pickButton.addEventListener('click', () => {
  betslipService.addPick({
    sport: 'SOCCER',
    eventId: 1000,
    marketId: 1001,
    pickId: Date.now(),

    riskValue: '',
    odds: getRandomOdds(),
    previousOdds: undefined,
    timeOddsChanged: undefined,
  })
})

setInterval(() => {
  betslipService.currentPicks.update(picks => {
    const copy = { ...picks }

    for (const pick of Object.values(copy)) {
      const newOdds = getRandomOdds()

      if (newOdds === pick.odds) {
        continue
      }

      pick.previousOdds = pick.odds
      pick.odds = newOdds
      pick.timeOddsChanged = Date.now()
    }

    return copy
  })
}, 5000)
